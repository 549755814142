<template>
    <Overview 
        :moduleName="'Netvibes Connect'"
        :moduleNamePlural="'Netvibes Connect'"
        :itemService=itemService
        :disableEdit=true
        :disableDelete=true
        :disableNewButton=true
    >

        <template #columns>
            <Column field="created_at" header="Date" :sortable="true">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.created_at, 'todayTime')}}
                </template>
            </Column>
            <Column field="url_parameter" header="Service" :sortable="true">
                <template #body="slotProps">
                    <div style='white-space: nowrap'>
                        <img :src="require(`@/assets/${slotProps.data.url_parameter}.png`)"  class="icon" />&nbsp;{{slotProps.data.url_parameter}}
                    </div>
                </template>
            </Column>

            <Column field="project" header="Project" :sortable="true"></Column>
            <!-- <Column field="action" header="Action" :sortable="true"></Column> -->
            <Column field="dynamic_data.message" header="Message">
                <template #body="slotProps">
                    <template v-if="slotProps.data.dynamic_data.message.title">{{slotProps.data.dynamic_data.message.title}} <br/></template>
                    <template v-for="event in slotProps.data.dynamic_data.message.events" :key="event.action">
                        <p><span class="badge text-bg-primary">{{event.action}}</span> {{event.action_description}}</p>

                    </template>
                </template>
            </Column>
            <Column field="dynamic_data.author" header="Author"></Column>

            <!-- <Column header="Json" style="width: 60%;">
                <template #body="slotProps">
                    <button v-if="displayPre != slotProps.data.id" @click="displayPre = slotProps.data.id" class="btn btn-primary">
                        View
                    </button>
                    <pre v-else @click="displayPre = false" style="background:#fff0d5;">{{JSON.parse(slotProps.data.body)}}</pre>
                </template>
            </Column> -->


        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import webhookDataService from '@/services/WebhookDataService';

export default {
    components: {
        Overview,
        Column
    },
    data() {
        return {
            itemService: webhookDataService,
            displayPre: false
        }
    }
}
</script>

<style>
.p-datatable-header{
    display:none;
}
.badge {
    border-radius: 2px;
    font-size: 0.7em;
    font-weight: normal;
}
.icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
}
.table.table-ellipsis tbody td {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
.table.table-ellipsis tbody td:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
}
.table img {
    margin-right: 5px;
}
</style>