<template>
    <div>
        <h1>{{moduleNamePlural}}</h1>

        <DataTable ref="dt" 
            :value="items.data" dataKey="id" 
            :totalRecords="items.total"
            :sortField="sort" :sortOrder="sortOrder"
            :paginator="true" :rows="20" :alwaysShowPaginator="false" :filters="filters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" responsiveLayout="scroll"
            :lazy="true" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" 
        >
            <template #header >
                <div class='row'>
                    <div class='col-sm-9'>
                        <template v-if="!disableNewButton">
                            <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#formModal" @click="addItem()" id='btnNew'><i class="fal fa-plus"></i> Nieuw</button>
                        </template>
                    </div>
                    <div class='col-sm-3 text-end'>
                        <input class="form-control" type="search" placeholder="Zoeken..." id="example-search-input" @change="onFilter()" v-model="filters['global'].value" autocomplete="off">
                    </div>
                </div>
            </template>

            <slot name="columns"></slot>
            <slot name="actions">
                <Column :exportable="false" style="width: 8rem">
                    <template #body="slotProps">
                        <div class="text-end">
                            <template v-if="!disableEdit">
                                <a class="fa-stack" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data)">
                                    <i class="fas fa-circle fa-stack-2x text-warning"></i>
                                    <i class="fal fa-pencil-alt fa-stack-1x fa-inverse"></i>
                                </a>
                            </template>

                            <template v-if="!disableDelete">
                                <a class="fa-stack" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="delItem(slotProps.data)">
                                    <i class="fas fa-circle fa-stack-2x text-danger"></i>
                                    <i class="fal fa-trash-alt fa-stack-1x fa-inverse"></i>
                                </a> 
                            </template>
                        </div>
                    </template>
                </Column>
            </slot>
        </DataTable>

        <FormPopup v-on:save="save" v-on:del="del" :itemId="item.id" :itemName="item ? item[sort] : null" :moduleName="moduleName" :loading="itemLoading">
            <slot name='form'></slot>
        </FormPopup>
    </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import FormPopup from '@/components/FormPopup.vue';

export default {
    name: 'Overview',
    props: {
        'moduleNamePlural': {},
        'moduleName': {},
        'sort': {},
        'sortOrder': { type: Number, default: 1},
        'itemService': {},
        'itemFormRef': {},
        'modalSize': {},
        'modalHeight': {},
        'modalWidth': {},
        'disableNewButton': {},
        'disableEdit': {},
        'disableDelete': {}
    },
    components: {
        DataTable,
        Column,
        FormPopup,
        // PortForm
    },
    emits: 'refreshList',
    data() {
        return {
            items: {
                'data': [],
                'total': 0
            },
            loading: true,
            lazyParams: {},
            page: 1,
            filters: {},
            
            item: {},
            itemLoading: false
        }
    },
    methods: {
        onPage(event) {
            this.page = event.page + 1
            this.getIndex();
        },
        onSort(event) {
            this.page = 1;
            this.lazyParams = event;
            this.getIndex();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.getIndex();
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },

        
        getIndex() {
          this.loading = true
          this.item = {}
          this.itemService.index(this.page, {
            lazyEvent: JSON.stringify(this.lazyParams)
          }).then(response => {
            this.items = response.data;
            this.loading = false
          }).catch(error => {
            this.loading = false
            console.log('error', error)
          })
        },
        addItem() {
            this.itemLoading = false;
            this.itemFormRef.reset();
            this.item = this.itemFormRef.getData();
            // console.log("Item is now", this.item);
        },
        editItem(item) {
            this.itemLoading = true;
            this.itemFormRef.reset();

            this.itemService.show(item.id).then(response => {
                this.itemLoading = false;
                this.item = response.data
                this.itemFormRef.setData(response.data);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
        },
        delItem(item) {
            this.item = {...item};
        },
        save() {
            let validate = this.itemFormRef.validate();
            if(!validate) return false;
            let item = this.itemFormRef.getData();
            
            if(this.item.id) {
                this.itemService.update(item).then(response => {
                    this.itemFormRef.reset();
                    this.getIndex();
                    document.querySelector("#formModal .btn-close").click();
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            } else {
                this.itemService.store(item).then(response => {
                    console.log('response', response);
                    this.itemFormRef.reset();
                    this.getIndex();
                    document.querySelector("#formModal .btn-close").click();
                }).catch(error => {
                        this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            }
            
        },
        del() {
            this.itemService.delete(this.item.id).then(response => {
                console.log(response);
                this.getIndex();
                document.querySelector("#deleteModal .btn-close").click();
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
            
        }
    },
    created() {
        // this.productService = new ProductService();
        this.initFilters();
    },
    mounted() {
        this.lazyParams = {
          first: 0,
          rows: this.$refs.dt.rows,
          sortField: this.sort,
          sortOrder: this.sortOrder,
          filters: this.filters
        }
        this.getIndex();

        
    }
}
</script>

<style scoped>

    .ms-n5 {
        margin-left: -40px;
    }

    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a.fa-stack {
        cursor: pointer;
    }

</style>

<style>
.pi-sort-alt:before {
    content: "";
}
</style>